import { Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

export const FEATURE = {
  name: 'BroadcastChannel'
};

@Injectable({
  providedIn: 'root'
})
export class BroadcastChannelService {
  constructor() {}

  createChannel(name: string): CIBroadcastChannel {
    return new CIBroadcastChannel(name);
  }
}

export class CIBroadcastChannel {
  name: string;
  private readonly channel: BroadcastChannel;
  private readonly listener: Subject<BCMessage> = new Subject<BCMessage>();
  private readonly errorListener: Subject<BCError> = new Subject<BCError>();
  private handshakeStatus = '';

  constructor(_name: string) {
    if (this.hasBrowserSupport()) {
      this.name = _name;
      this.channel = new BroadcastChannel(this.name);
      this.channel.onmessage = this.handleMessages.bind(this);
      this.doHandshake();
    } else {
      const error: BCError = {
        code: BC_RESERVED_ERR_CODEs.unsupported_browser
      };
      this.errorListener = new BehaviorSubject<BCError>(error);
    }
  }

  private doHandshake(): void {
    this.handshakeStatus = HANDSHAKE.inProgress;

    const handshakeMessage: BCMessage = {
      id: BC_RESERVED_MSG_IDs.handshake
    };
    this.post(handshakeMessage);

    this.watchHandshakeTimeout();
  }

  private watchHandshakeTimeout(): void {
    interval(1000)
      .pipe(take(1))
      .subscribe(() => {
        if (this.handshakeStatus === HANDSHAKE.inProgress) {
          this.handshakeStatus = HANDSHAKE.failure;
          const error: BCError = {
            code: BC_RESERVED_ERR_CODEs.no_peers
          };
          this.errorListener.next(error);
        }
      });
  }

  private handleMessages(message: MessageEvent): void {
    switch (message.data.id) {
      case BC_RESERVED_MSG_IDs.handshake:
        const connectedRes: BCMessage = {
          id: BC_RESERVED_MSG_IDs.connected
        };
        this.post(connectedRes);
        break;
      case BC_RESERVED_MSG_IDs.connected:
        if (this.handshakeStatus === HANDSHAKE.inProgress) {
          this.handshakeStatus = HANDSHAKE.success;
          this.listener.next(message.data);
        }
        break;
      default:
        this.listener.next(message.data);
        break;
    }
  }

  post(message: BCMessage): void {
    this.channel.postMessage(message);
  }

  onMessage(): Observable<BCMessage> {
    return this.listener;
  }

  onError(): Observable<BCError> {
    return this.errorListener;
  }

  hasBrowserSupport(): boolean {
    return FEATURE.name in self;
  }
}

export interface BCMessage {
  id: string;
  senderId?: string;
  message?: any;
}

export interface BCError {
  code: string;
  message?: any;
}

export enum BC_RESERVED_MSG_IDs {
  handshake = 'bc_handshake',
  connected = 'bc_connected',
  drop = 'bc_drop'
}

export enum BC_RESERVED_ERR_CODEs {
  no_peers = 'bc_err_no_peers',
  unsupported_browser = 'unsupported_browser'
}

enum HANDSHAKE {
  inProgress = 'in-progress',
  success = 'success',
  failure = 'failure'
}
