import { localStorageHelper } from '@Terra/shared/widgets/utils';
import { Injectable } from '@angular/core';
import { UTILS_CONSTANTS } from './utils.config';

@Injectable()
export class CPUtilService {
  constructor() {}

  resetLocalStorage(): void {
    const cookieSetting = localStorageHelper.getItem(UTILS_CONSTANTS.MARKETING_CONSTANTS.COOKIE_SETTINGS);
    const deviceIDs = localStorage.getItem(UTILS_CONSTANTS.DEVICEIDS) || '{}';
    const multipartFile = localStorage.getItem(UTILS_CONSTANTS.MULTIPARTFILE);
    const cpCookie = localStorageHelper.getItem(UTILS_CONSTANTS.CP_COOKIE);

    localStorageHelper.clearItem();

    if (cookieSetting === UTILS_CONSTANTS.MARKETING_CONSTANTS.ACCEPT || cookieSetting === UTILS_CONSTANTS.MARKETING_CONSTANTS.DISMISS) {
      localStorageHelper.setItem(UTILS_CONSTANTS.MARKETING_CONSTANTS.COOKIE_SETTINGS, cookieSetting);
    }
    localStorage.setItem(UTILS_CONSTANTS.DEVICEIDS, deviceIDs);
    if (multipartFile) {
      localStorage.setItem(UTILS_CONSTANTS.MULTIPARTFILE, multipartFile);
    }
    if (cpCookie) {
      localStorageHelper.setItem(UTILS_CONSTANTS.CP_COOKIE, cpCookie);
    }
  }

  flushLocalStorage(): void {
    localStorageHelper.clearItem();
  }
}
