import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SharedLibState } from '../+state/shared-lib.reducer';

@Component({
  selector: 'app-spinner',
  templateUrl: './app-spinner.component.html'
})
export class AppSpinnerComponent implements OnInit, OnDestroy {
  private readonly subsciptions = new Subscription();
  public canShowSpinner = false;
  @Input() loaderName: string;

  constructor(private readonly store: Store<SharedLibState>, private readonly cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.subsciptions.add(
      this.store
        .select(state => state.sharedLib.spinner)
        .subscribe(spinnerStatus => {
          this.canShowSpinner = spinnerStatus;
          this.canShowSpinner ? document.body.classList.add('spinner-visible') : document.body.classList.remove('spinner-visible');
          this.cdr.markForCheck();
        })
    );
  }

  ngOnDestroy() {
    this.subsciptions.unsubscribe();
  }
}
