import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { SharedLibState } from './+state/shared-lib.reducer';
import { LANG_CODES } from './languages.config';
import {
  APP_IDS,
  CP_USER_PREFERENCE_VALUE,
  METHOD_NAME,
  PreferenceValue,
  PreferenceVisibility,
  URL_PATH,
  USER_PREFERENCE_VALUE,
  WOA_URL_PATH
} from './models/shared-lib.model';

@Injectable()
export class SharedLibService {
  customHeaders: { headerName: string; headerValue: any }[];
  userPreference: { userPreferenceName: string; userPreferenceValue: any }[];
  envRef: any;
  currentUrl: string;
  previousUrl: string;
  applicationType: string;

  constructor(private readonly http: HttpClient, private readonly store: Store<SharedLibState>, private readonly router: Router) {
    this.store
      .select((state: any) => state.environment)
      .subscribe(r => {
        this.envRef = r;
      });

    this.currentUrl = this.router.url;

    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  getPreviousUrl() {
    return this.previousUrl;
  }

  openFullScreen() {
    const documentElm = document.documentElement;
    const openFullscreenFn =
      documentElm.requestFullscreen ||
      documentElm['mozRequestFullScreen'] ||
      documentElm['webkitRequestFullscreen'] ||
      documentElm['msRequestFullscreen'];
    if (openFullscreenFn) {
      openFullscreenFn.call(documentElm);
    }
  }

  closeFullScreen() {
    const closeFullscreenFn =
      document.exitFullscreen || document['mozCancelFullScreen'] || document['webkitExitFullscreen'] || document['msExitFullscreen'];
    if (closeFullscreenFn) {
      closeFullscreenFn.call(document);
    }
  }

  postDataToCrdsService(param) {
    const apiBaseUrl = this.envRef.nodeBaseURL + METHOD_NAME.POST;
    const handlerURL = this.envRef.crds_downloadURL + URL_PATH.app + param.appId + URL_PATH.report + URL_PATH.download;

    const body = {
      params: param.data,
      handlerURL,
      customheaders: JSON.stringify({
        'Content-Type': 'application/json'
      })
    };

    const params = param.spinner === true ? new HttpParams().append('canShowSpinner', 'true') : new HttpParams();
    return this.http.post<any>(apiBaseUrl, body, { params, responseType: 'text' as 'json' }).pipe(tap((response: any) => response));
  }

  downloadExcelService(reportParam) {
    const webApiBaseUrl = reportParam.wafEnabled ? '/' : this.envRef.crds_downloadURL;
    const handlerURL = `${webApiBaseUrl + URL_PATH.app + reportParam.appId + URL_PATH.report}/${reportParam.report + URL_PATH.download}`;
    const apiURL =
      reportParam.wafEnabled === true ? this.envRef.nodeBaseURL + WOA_URL_PATH.download : this.envRef.nodeBaseURL + METHOD_NAME.GET;

    const params: HttpParams =
      reportParam.spinner === true
        ? new HttpParams().append(URL_PATH.handlerURL, handlerURL).append('canShowSpinner', 'true')
        : new HttpParams().append(URL_PATH.handlerURL, handlerURL);
    return this.http.get<any>(apiURL, { params: params.set('responseType', 'arraybuffer'), responseType: 'blob' as 'json' }).pipe(
      tap((response: any) => response),
      catchError(this.handleError)
    );
  }

  /**
   * Get the preference value based on matrix
   * Use this method to get the field value and conflict value
   * @param appPreference - eRoutes preference value ex. DD MMM YYYY
   * @param heliosPreference - Helios preference value ex. ddmmmyyyy
   * @param isHeliosMigrated - Whether user migrated to Helios
   * @return PreferenceValue
   */
  getValueFromPreferenceMatrix(appPreference: string, heliosPreference: string, isHeliosMigrated: boolean): PreferenceValue {
    const PREFERENCE_VALUE =
      !this.envRef?.applicationID || this.envRef?.applicationID === APP_IDS.EROUTES ? USER_PREFERENCE_VALUE : CP_USER_PREFERENCE_VALUE;
    const ret: PreferenceValue = {
      fieldValue: PREFERENCE_VALUE[heliosPreference],
      fieldConflictValue: null
    };
    if (!heliosPreference) {
      // no preference set in Helios, new user
      ret.fieldValue = appPreference;
      return ret;
    }
    if (!PREFERENCE_VALUE[heliosPreference]) {
      // Helios preference not supported in eRoutes
      ret.fieldValue = appPreference;
      ret.fieldConflictValue = heliosPreference;
      return ret;
    }
    if (PREFERENCE_VALUE[heliosPreference] !== appPreference && !isHeliosMigrated) {
      // Helios preference conflict with eRoutes preference
      ret.fieldValue = appPreference;
      ret.fieldConflictValue = heliosPreference;
      return ret;
    }
    return ret;
  }

  /**
   * Get the preference popup visibility based on matrix
   * Use this method to identify whether to show/hide the preference popup on page load
   * @param appPreference - eRoutes preference value ex. DD MMM YYYY
   * @param heliosPreference - Helios preference value ex. ddmmmyyyy
   * @param isHeliosMigrated - Whether user migrated to Helios
   * @return PreferenceVisibility
   */
  getVisibilityFromPreferenceMatrix(appPreference: string, heliosPreference: string, isHeliosMigrated: boolean): PreferenceVisibility {
    const PREFERENCE_VALUE =
      !this.envRef?.applicationID || this.envRef?.applicationID === APP_IDS.EROUTES ? USER_PREFERENCE_VALUE : CP_USER_PREFERENCE_VALUE;
    const ret: PreferenceVisibility = {
      visible: false
    };
    if (
      !heliosPreference || // no preference set in Helios, new user
      !PREFERENCE_VALUE[heliosPreference] || // Helios preference not supported in eRoutes
      (PREFERENCE_VALUE[heliosPreference] !== appPreference && !isHeliosMigrated) // Helios preference conflict with eRoutes preference
    ) {
      ret.visible = true;
    }
    return ret;
  }
  /* Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(err) {
    const errorMessage = `An error occurred: ${err.error.message} || ${err.error.Message}`;
    return throwError(() => new Error(errorMessage));
  }

  getLangCodesFor(system: string, code?: string): any {
    return code ? LANG_CODES[system][code] : LANG_CODES[system];
  }

  /* istanbul ignore next */
  isIeOrEdge() {
    const userAgent = navigator.userAgent;
    return /Edge/.test(userAgent) || /Trident/.test(userAgent);
  }
}
