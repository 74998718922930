export const UTILS_CONSTANTS = {
  MARKETING_CONSTANTS: {
    COOKIE_SETTINGS: 'CookieSettings',
    ACCEPT: 'accept',
    DISMISS: 'dismiss'
  },
  DEVICEIDS: 'deviceIDs',
  MULTIPARTFILE: 'UPLOADMULTIPARTFILE',
  UCIDDATA: 'ucidData',
  SELECTED_MULTIPLE_ASSET: 'selectedMultipleAsset',
  LOGOUT: 'logOut',
  CP_COOKIE: 'CPCookie'
};

export const UPSERT_SITE_STEPPER = {
  SITE_ADDRESS_STEP: 1,
  ASSETS_STEP: 2,
  GEOFENCE_STEP: 3,
  SHIFT_STEP: 4,
  SUMMARY_REVIEW_STEP: 5
};

export const MULTIPARTFILE_LOCALSTORAGE_MAX_AGE = 24 * 60 * 60 * 1000; // 24 hr in ms

export const DEMO_MODE_KEY = 'isDemoMode';

export const DEMO_MODE_RELOADED_KEY = 'isReloadedForDemo';

export const DEMO_MODE_DISABLE_CONFIG = { disable: true };

export const DEMO_MODE_HIDE_CONFIG = { hide: true };
