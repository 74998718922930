export const listType = {
  cpmResponse: `[{"make":"Z88","serialnumber":"11KCQA00001","listNames":[{"listName":"Material",
		"maxRecord":500},{"listName":"Truck","maxRecord":500},{"listName":"LoadSite","ma
		xRecord":500}]}]`
    .toString()
    .replace(/\n/gi, '')
    .replace(/\s\s+/g, ''),
  cpmResponseCode: '200',
  cpmResponseStatus: true
};

export const listTypeWithNoLastNames = {
  cpmResponse: `[{"make":"Z88","serialnumber":"11KCQA00001"}]`.toString().replace(/\n/gi, '').replace(/\s\s+/g, ''),
  cpmResponseCode: '200',
  cpmResponseStatus: true
};
