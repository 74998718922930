export const dashboardUtilMockInfo = {
  indiana_AsiaC: 'Asia/Calcutta',
  industryId_0897: '08972290-DAF3-E911-B5E9-501AC51B1F7D',
  timeZone_IST: 'India Standard Time',
  guid_f45a: 'f45ae924-d74c-42f0-8859-069949366d01'
};

export const PreferenceMockData = {
  autoRefresh: '',
  dateFormat: 'MMM DD, YYYY',
  language: 'bg-bg',
  remember: false,
  timeFormat: '12 hour',
  timeZone: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  unit: 'U.S. Customary',
  decimalSeparator: 'Point',
  favoriteSites: {
    '2972056560': [45678, 240788, 247142],
    '2972056559': [],
    '2972056558': []
  },
  ucid: '2972056560',
  defaultPreferenceCP: false
};

export const UcidDataMock = {
  catRecID: 'QPS-293D998B',
  cwsid: 'dv3',
  userRoleID: 'cf361de7-a3b5-41c8-af3e-87684f99f11f',
  userRoleName: 'Super Admin',
  tenantID: 3397,
  ucid: '2972056560',
  partyName: 'test account',
  applicationID: 1
};

export const MockJobsites = [
  {
    ianaTimeZone: dashboardUtilMockInfo.indiana_AsiaC,
    id: 12345,
    name: 'JobSite 1',
    siteCreationDate: '',
    industryId: dashboardUtilMockInfo.industryId_0897,
    isFavorite: false,
    timeZone: dashboardUtilMockInfo.timeZone_IST,
    startDate: '',
    endDate: '',
    location: { latitude: '80', longitude: '0' } as any,
    guid: dashboardUtilMockInfo.guid_f45a,
    status: 'Active'
  },
  {
    ianaTimeZone: dashboardUtilMockInfo.indiana_AsiaC,
    id: 240788,
    name: 'JobSite 1',
    siteCreationDate: '',
    industryId: dashboardUtilMockInfo.industryId_0897,
    isFavorite: 'false',
    timeZone: dashboardUtilMockInfo.timeZone_IST,
    startDate: '',
    endDate: '',
    location: { latitude: '80', longitude: '0' } as any,
    guid: dashboardUtilMockInfo.guid_f45a,
    status: 'Active'
  },
  {
    ianaTimeZone: dashboardUtilMockInfo.indiana_AsiaC,
    id: 247142,
    name: 'JobSite 1',
    siteCreationDate: '',
    industryId: dashboardUtilMockInfo.industryId_0897,
    isFavorite: true,
    timeZone: dashboardUtilMockInfo.timeZone_IST,
    startDate: '',
    endDate: '',
    location: { latitude: '80', longitude: '0' } as any,
    guid: dashboardUtilMockInfo.guid_f45a,
    status: 'Active'
  }
];
