import { AppState } from '@Terra/shared/shared-lib';
import { UCIDData } from '@Terra/shared/widgets/interface';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, shareReplay } from 'rxjs/operators';
import { OMS_API_ENDPOINTS, OMS_NODE_SERVER } from './oms.config';
import * as omsHelper from './oms.helper';
import { OperatorListResponse, OperatorMapping } from './oms.model';

@Injectable()
export class OMSService implements OnDestroy {
  ucid: string = '';
  operatorKeyMapList$: BehaviorSubject<OperatorMapping> = new BehaviorSubject<OperatorMapping>(null);
  operatorIdMapList$: BehaviorSubject<OperatorMapping> = new BehaviorSubject<OperatorMapping>(null);
  storeSubscription: Subscription = new Subscription();
  makeSerialNumber: string = '';

  constructor(private readonly http: HttpClient, private readonly appStore: Store<AppState>) {
    this.subscribeAppStore();
  }

  /**
   * Subscribe the user data from store data
   */
  subscribeAppStore(): void {
    this.storeSubscription.add(
      this.appStore
        .select((appStore: AppState) => appStore.app?.selectedParty)
        .pipe(filter((res: UCIDData) => !!res))
        .subscribe((response: UCIDData) => {
          this.ucid = response.ucid;
        })
    );
  }

  /**
   * Load operator details API call
   */
  loadOperatorDetailsAPICall(makeSerialNumber: string): void {
    if (this.makeSerialNumber !== makeSerialNumber) {
      const queryParamUrl = OMS_API_ENDPOINTS.operatorParamDetails
        .replace('<makeSerialNumber>', makeSerialNumber)
        .replace('<ucid>', this.ucid)
        .replace('<offset>', '0')
        .replace('<limit>', '100');
      const handlerUrl = `${OMS_API_ENDPOINTS.operatorDetails}?${queryParamUrl}`;
      const params = new HttpParams().append(OMS_NODE_SERVER.HANDLER_URL, handlerUrl);
      this.http
        .get<OperatorListResponse>(OMS_NODE_SERVER.GET_OPERATORS, { params })
        .pipe(shareReplay(1))
        .subscribe({
          next: (operatorListResponse: OperatorListResponse) => {
            const { oprIdMap, keyIdMap } = omsHelper.prepareOperatorList(operatorListResponse);
            this.makeSerialNumber = makeSerialNumber;
            this.operatorKeyMapList$.next(keyIdMap);
            this.operatorIdMapList$.next(oprIdMap);
          },
          error: () => {
            this.makeSerialNumber = '';
            this.operatorKeyMapList$.next({});
            this.operatorIdMapList$.next({});
          }
        });
    }
  }

  /**
   * To unsubscribe the store subscription.
   */
  ngOnDestroy() {
    this.storeSubscription.unsubscribe();
  }
}
