/* eslint-disable complexity */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { decimalSeperatorHelper, roundoffHelper } from '@Terra/shared/widgets/pipes';

import { Preference } from '@Terra/shared/widgets/interface';
import { uomHelperConfig, utilsHelper } from '@Terra/shared/widgets/utils';
import { COST_CURRENCY_CONFIG, KPI_CONFIG, UOM_CONSTANT } from './dashboardUtil.config';
import { KPIConfig } from './dashboardUtil.model';

@Injectable()
export class DashboardUtilService {
  constructor(private readonly translocoService: TranslocoService) {}
  /**
   * To convert seconds to required time format
   * @param seconds seconds from response
   */
  getSecondsInHMS(seconds: number) {
    let hour = 0,
      minute = 0;
    seconds = Math.round(seconds); //rounding off milliseconds
    minute = Math.floor(seconds / 60); //converting secs to mins
    seconds = Math.round(((seconds % 60) * 100) / 100); //remaining seconds after taking hours
    hour = Math.floor(minute / 60); //converting mins to hrs
    minute = minute % 60; //remanining mins after taking hours
    if (hour !== 0 && seconds >= 30) {
      //Only if hour is present, we round off seconds >=30
      if (minute === 59) {
        hour = hour + 1;
        minute = 0;
      } else {
        minute += 1;
      }
      seconds = 0;
    }
    if (seconds === 60) {
      minute += 1;
      seconds = 0;
    }
    return {
      hr: hour,
      min: minute,
      sec: seconds
    };
  }

  /**
   * Method to convert litres into gallons
   * @param liters value of litre which will convert in gallon
   */
  convertLitersToGallon(liters: number, preference: Preference): string {
    const litreToGallon = utilsHelper.handleValueAndConversion(
      liters,
      uomHelperConfig.UOM_CONVERSION_CONSTANT_BASED_ON_UNIT[preference?.unit]?.LITRE_TO_GALLON
    );
    return `<span class="kpi-value">${this.roundOffAndDecimalConversion(
      litreToGallon,
      preference
    )}</span> <span class="kpi-unit">${this.translocoService.translate(
      uomHelperConfig.UOM_CONVERSION_TRANSLATION_BASED_ON_UNITS[preference?.unit]?.SHARED_LITER_GALLON
    )}</span>`;
  }

  /**
   * Method to convert litres into gallons
   * @param liters value of litre which will convert in gallon
   */
  convertLitersToGallonPerHour(liters: number, preference: Preference): string {
    const litreToGallon = utilsHelper.handleValueAndConversion(
      liters,
      uomHelperConfig.UOM_CONVERSION_CONSTANT_BASED_ON_UNIT[preference?.unit]?.LITRE_TO_GALLON
    );
    return `<span class="kpi-value">${this.roundOffAndDecimalConversion(
      litreToGallon,
      preference
    )}</span> <span class="kpi-unit">${this.translocoService.translate(
      uomHelperConfig.UOM_CONVERSION_TRANSLATION_BASED_ON_UNITS[preference?.unit]?.LITER_GALLON_HOUR
    )}</span>`;
  }

  /**
   * Method to convert Tonnes into US Tons
   * @param tonnes value of load in tonnes
   */
  convertTonnesToTons(tonnes: number, preference: Preference): string {
    const tonnesToTon = utilsHelper.handleValueAndConversion(
      tonnes,
      uomHelperConfig.UOM_CONVERSION_CONSTANT_BASED_ON_UNIT[preference?.unit].TONNES_TONS,
      preference?.unit
    );
    return `<span class="kpi-value">${this.roundOffAndDecimalConversion(
      tonnesToTon,
      preference
    )}</span> <span class="kpi-unit">${this.translocoService.translate(
      uomHelperConfig.UOM_CONVERSION_TRANSLATION_BASED_ON_UNITS[preference?.unit].SHARED_TONNES_TONS
    )}</span>`;
  }

  convertTonnesToTonsWithoutUnit(tonnes: number, preference: Preference): string {
    const tonnesToTon = utilsHelper.handleValueAndConversion(
      tonnes,
      uomHelperConfig.UOM_CONVERSION_CONSTANT_BASED_ON_UNIT[preference?.unit]?.TONNES_TONS,
      preference?.unit
    );
    return `<span class="kpi-value">${this.roundOffAndDecimalConversion(tonnesToTon, preference)}</span>`;
  }

  /**
   * Method to convert cost per payload value
   * @param meter value of load in tonnes
   */
  convertCostPerPayload(value: number, preference: Preference): number {
    const conversion = utilsHelper.handleValueAndConversion(
      value,
      uomHelperConfig.UOM_CONVERSION_CONSTANT_BASED_ON_UNIT[preference?.unit]?.TONS_TONNES_WEIGHT_US,
      preference?.unit
    );
    return this.roundOffAndDecimalConversion(conversion, preference);
  }

  /**
   * Method to convert cost per voulme value
   * @param value of load in volume
   */
  convertCostPerVolume(value: number, preference: Preference): number {
    const conversion = utilsHelper.handleValueAndConversion(
      value,
      uomHelperConfig.UOM_CONVERSION_CONSTANT_BASED_ON_UNIT[preference?.unit]?.METER_YARD_COST
    );
    return this.roundOffAndDecimalConversion(conversion, preference);
  }

  convertMeterToYard(meter: number, preference: Preference): string {
    const meterToYard = utilsHelper.handleValueAndConversion(
      meter,
      uomHelperConfig.UOM_CONVERSION_CONSTANT_BASED_ON_UNIT[preference?.unit]?.METER_TO_YARD
    );
    return `<span class="kpi-value">${this.roundOffAndDecimalConversion(meterToYard, preference)}</span> <span class="kpi-unit">${
      uomHelperConfig.UOM_CONVERSION_TRANSLATION_BASED_ON_UNITS[preference?.unit]?.YD_M
    }</span>`;
  }

  convertSecondstoHrsMin(value: number): string {
    let kpiValue = '';
    if (value === 0) {
      kpiValue += `${value} ${this.translocoService.translate('sharedUtil.sec')}`;
    } else {
      const HMS = this.getSecondsInHMS(value);
      const hours = HMS.hr,
        minutes = HMS.min,
        seconds = HMS.sec;
      if (hours) {
        kpiValue += `<span class="kpi-value">${hours}</span> <span class="kpi-unit">${this.translocoService.translate(
          'sharedUtil.hr'
        )}</span>`;
        if (minutes) {
          kpiValue += ` <span class="kpi-value">${minutes}</span> <span class="kpi-unit">${this.translocoService.translate(
            'sharedUtil.min'
          )}</span>`;
        }
      } else {
        if (minutes) {
          kpiValue += `<span class="kpi-value">${minutes}</span> <span class="kpi-unit">${this.translocoService.translate(
            'sharedUtil.min'
          )}</span>`;
        }
        if (seconds) {
          kpiValue += ` <span class="kpi-value">${seconds}</span> <span class="kpi-unit">${this.translocoService.translate(
            'sharedUtil.sec'
          )}</span>`;
        }
      }
    }
    return kpiValue;
  }
  roundOffAndDecimalConversion(value: number, preference: Preference): number {
    return decimalSeperatorHelper.decimalSeparatorTransform(roundoffHelper.roundOffTransform(value), preference.decimalSeparator);
  }

  convertKPIValueOnNotMatchUom(value: number, preference: Preference): string {
    return value >= 0
      ? `<span class="kpi-value">${this.roundOffAndDecimalConversion(value, preference)}</span>`
      : `<span class="kpi-value">--</span>`;
  }
  convertKPIValueBasedOnUom(value: number, kpiId: string, preference: Preference, currencySymbol?: string): string {
    if (this.checkNullOrUndefined(value)) {
      return '<span class="kpi-value"> -- </span>';
    }
    let kpiValue;
    if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.sec) {
      kpiValue = this.convertSecondstoHrsMin(value);
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.tonnes && kpiId === 'PayloadPerHour') {
      kpiValue = this.convertTonnesToTonsWithoutUnit(value, preference);
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.tonnes) {
      kpiValue = this.convertTonnesToTons(value, preference);
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.litre) {
      kpiValue = this.convertLitersToGallon(value, preference);
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.litreperhour) {
      kpiValue = this.convertLitersToGallonPerHour(value, preference);
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.cubicMeter) {
      kpiValue = this.convertMeterToYard(value, preference);
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.costperpayload) {
      kpiValue = `<span class="kpi-value">${this.getCurrencySymbol(currencySymbol) + this.convertCostPerPayload(value, preference)}</span>`;
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.costpervolume) {
      kpiValue = `<span class="kpi-value">${this.getCurrencySymbol(currencySymbol) + this.convertCostPerVolume(value, preference)}</span>`;
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.percentage) {
      kpiValue = ` <span class="kpi-value">${this.roundOffAndDecimalConversion(value, preference)}<span class="kpi-percent-value">${
        UOM_CONSTANT.percentage
      }</span></span>`;
    } else if (KPI_CONFIG[kpiId]?.uom === UOM_CONSTANT.currency) {
      kpiValue = `<span class="kpi-value">${
        this.getCurrencySymbol(currencySymbol) + this.roundOffAndDecimalConversion(value, preference)
      }</span>`;
    } else {
      kpiValue = this.convertKPIValueOnNotMatchUom(value, preference);
    }
    return kpiValue;
  }

  /**
   * To return currency symbol for the cost
   * @param currencySymbol pass from api response
   */
  getCurrencySymbol(currencySymbol: string): string {
    return COST_CURRENCY_CONFIG.find(({ key }) => key === currencySymbol)?.symbol;
  }

  getKPIConfig(value: number, kpiId: string, preference: Preference, currencySymbol?: string): KPIConfig {
    return {
      kpiValue: this.convertKPIValueBasedOnUom(value, kpiId, preference, currencySymbol),
      imgSrc: KPI_CONFIG[kpiId]?.imageSrc
    };
  }

  getKpiImg(kpiId: string): string {
    return KPI_CONFIG[kpiId]?.imageSrc;
  }

  getKpiValue(value: number, kpiId: string, preference: Preference, currencySymbol?: string): string {
    return this.convertKPIValueBasedOnUom(value, kpiId, preference, currencySymbol);
  }

  checkNullOrUndefined(value: any): boolean {
    return value === null || value === undefined;
  }
}
