import { Preference, UCIDData } from '@Terra/shared/widgets/interface';
import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { Favorite } from './dashboardUtil.model';

@Injectable({
  providedIn: 'root'
})
export class GlobalUtilService {
  $ucidData = new Subject<UCIDData>();
  $preferenceData = new Subject<Preference>();
  subscriptions = new Subscription();
  ucidData: UCIDData;
  preferenceData: Preference;

  constructor() {
    this.subscribeUcidData();
    this.subscribePreferenceData();
  }

  subscribeUcidData() {
    const ucidDataSub = this.$ucidData.subscribe(ucidData => (this.ucidData = ucidData));
    this.subscriptions.add(ucidDataSub);
  }

  subscribePreferenceData() {
    const prefDataSub = this.$preferenceData.subscribe(
      (preferenceData: Preference) =>
        (this.preferenceData =
          typeof preferenceData.favoriteSites === 'string'
            ? { ...preferenceData, favoriteSites: JSON.parse(preferenceData.favoriteSites) }
            : preferenceData)
    );
    this.subscriptions.add(prefDataSub);
  }

  getFavoriteSites(): number[] {
    const favorites = this.preferenceData.favoriteSites?.[this.ucidData.ucid];
    return favorites ? favorites : [];
  }

  mapJobSiteFavorite(sitesList: any[], isFavSort: boolean) {
    const favoriteSites = this.getFavoriteSites();
    let favSitesData = [...favoriteSites] as any;
    sitesList.forEach(site => {
      const index = favoriteSites.findIndex(id => id === site.id);
      site['isFavorite'] = index !== -1;
      site['isFavorite'] && (favSitesData[index] = site);
    });
    if (isFavSort) {
      favSitesData = favSitesData.filter(site => site?.id);
      sitesList.sort((a, b) => (a.isFavorite && b.isFavorite ? 0 : a.isFavorite ? -1 : 1));
      sitesList.splice(0, favSitesData.length, ...favSitesData);
    }
    return sitesList;
  }

  patchFavoriteSite(favorites: Favorite[]) {
    let sites = this.getFavoriteSites();
    favorites.forEach(favorite => {
      if (favorite.isFavorite) {
        sites.unshift(favorite.siteId);
      } else {
        sites = sites.filter(id => id !== favorite.siteId);
      }
    });
    return {
      ...(typeof this.preferenceData.favoriteSites === 'string'
        ? JSON.parse(this.preferenceData.favoriteSites)
        : this.preferenceData.favoriteSites),
      [this.ucidData.ucid]: [...new Set(sites)]
    };
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
