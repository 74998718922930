import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS, NODE_SERVER, configStrings } from './siteMsUtil.config';
import { SiteBoundaryIntersectDetails } from './siteMsUtil.model';

@Injectable()
export class SiteMsUtilService {
  constructor(private readonly http: HttpClient) {}

  loadSiteBoundary(siteGuid: string, reqBody?, showSpinner = false): Observable<SiteBoundaryIntersectDetails> {
    const options = {
      headers: new HttpHeaders().append(configStrings.contentType, configStrings.applicationJson),
      params: new HttpParams().append('canShowSpinner', showSpinner.toString())
    };

    const body = {
      handlerURL: API_ENDPOINTS.siteBoundaryStatus.replace('{site_guid}', siteGuid),
      customheaders: JSON.stringify({
        [configStrings.contentType]: configStrings.applicationJson
      })
    };
    if (reqBody) {
      body['params'] = reqBody;
    }
    return this.http.post<SiteBoundaryIntersectDetails>(NODE_SERVER.TERRASITE_ROUTE, body, options);
  }
}
