import { FavoriteSites, Feature } from '@Terra/shared/widgets/interface';

export enum API_STATUS {
  SUCCESS = 1,
  INTERNAL_SERVER_ERROR = 500,
  UN_AUTHORIZED = 401,
  TOAST_SUCCESS = 200
}

export interface ApiStatus {
  status: API_STATUS;
  message: string;
  showToast?: boolean;
  color?: string;
  lifeTime?: number;
  autoClose?: boolean;
}

export interface CustomHeaders {
  headerName: string;
  headerValue: string;
}

export interface UserPreference {
  userPreferenceName: string;
  userPreferenceValue: string;
}

export interface Preference {
  dateFormat: string;
  timeFormat: string;
  unit: string;
  language: string;
  groupId?: number;
  ucid?: string;
  globalGroupId?: number;
  autoRefresh?: string;
  remember?: boolean;
  decimalSeparator?: string;
  favoriteSites?: any;
  defaultPreferenceCP?: boolean;
}
export interface AppStateModel {
  preferenceData: Preference;
  groupData: any;
  selectedParty: UcidData;
  isPreferenceUpdated: boolean;
  featureList: Feature[];
}

export interface ProjectDetail {
  name?: string;
  id?: string;
}

export const DATA_COLLECTION = {
  templateID: 0,
  headerLeft: '',
  headerCenter: '',
  headerRight: '',
  footerLeft: '',
  footerCenter: '',
  footerRight: '',
  displayTemplate: 0,
  reportName: '',
  dashboardName: '',
  userPreferences: null,
  isReadOnly: false,
  dataCollection: [
    {
      dataURL: '',
      dataBody: '',
      urlOrder: 0,
      description: '',
      httpVerb: 'GET',
      responseType: 'JSON',
      widgetName: '',
      dateFormat: ''
    }
  ],
  reportLanguage: ''
};

export interface DataCollection {
  templateID: number;
  headerLeft: string;
  headerCenter: string;
  headerRight: string;
  footerLeft: string;
  footerCenter: string;
  footerRight: string;
  displayTemplate: number;
  reportName: string;
  dashboardName: string;
  userPreferences: UserPreference[];
  isReadOnly: boolean;
  dataCollection: DataModal[];
  reportLanguage: string;
}

export interface ENV {
  local: number;
  dev: number;
  int: number;
  qa: number;
  perf: number;
  stg: number;
  prod: number;
}

export const TEMPLATE_ID: { [key: string]: ENV } = {
  xlsx: {
    local: 5588,
    dev: 5588,
    int: 25,
    qa: 272,
    perf: 28,
    // stg: 29, // ACTUAL TEMPLATE ID FOR STG
    stg: 33, // MODIFIED to replicate STG as PROD
    prod: 33
  },
  pdf: {
    local: 9631,
    dev: 9631,
    int: 32,
    qa: 279,
    perf: 35,
    //stg: 36, // ACTUAL TEMPLATE ID FOR STG
    stg: 41, // MODIFIED to replicate STG as PROD
    prod: 41
  },
  csv: {
    int: 4381,
    qa: 283,
    // stg: 41, //ACTUAL TEMPLATE ID FOR STG
    stg: 46, //MODIFIED to replicate STG as PROD
    prod: 46,
    perf: 38,
    local: 26210,
    dev: 26210
  }
};

export interface FileExtensions {
  pdf: boolean;
  xlsx: boolean;
  csv: boolean;
}
export interface DataModal {
  dataURL: string;
  dataBody: string;
  urlOrder: number;
  description: string;
  httpVerb: string;
  responseType: string;
  widgetName: string;
  dateFormat: string;
}

export const APPLICATION_ID = {
  dev: 6482,
  int: 9,
  qa: 277,
  perf: 9,
  // stg: 10, // Actual STG APP_ID
  stg: 11, // Pointing to PROD APP_ID to support PROD data flowing into STG
  prod: 11,
  local: 6482
};

export const METHOD_NAME = {
  GET: '/apigetdata',
  POST: '/apipostdata',
  PUT: '/apiputdata',
  DELETE: '/apideletedata'
};

export const URL_PATH = {
  app: 'api/applications/',
  report: '/reports',
  handlerURL: 'handlerURL',
  download: '/directdownloads'
};

export const WOA_URL_PATH = {
  download: '/crdsFileDownload?'
};

export const DATE_FORMAT = 'MM/dd/yyyy hh:mm:ss';

export const USER_ROLES = {
  SUPER_ADMIN: 'Super Admin',
  SYSTEM_ADMIN: 'System Admin',
  ADMIN: 'Admin',
  DEALER: 'Dealer',
  OPERATIONS: 'Operations',
  NORMAL: 'Normal'
};

export interface UcidData {
  catRecID: string;
  cwsid: string;
  userRoleID: string;
  userRoleName: string;
  ucid: string;
  applicationID: number;
  partyName: string;
}
export interface PreferenceVisibility {
  visible: boolean;
}
export interface PreferenceValue {
  fieldValue: string;
  fieldConflictValue: string;
}

export interface HeliosCustomPreference {
  ucid?: string;
  favoriteSites?: FavoriteSites | string;
  consentStatus?: string;
  isFailed?: boolean;
}

export const REPORT_FORMAT_FILE_TYPE_MAPPING = {
  xlsx: 'XLS',
  pdf: 'PDF',
  csv: 'CSV'
};

/**
 * Helios preference and eRoutes preference value mapping
 * Key - Helios preference
 * Value - eRoutes preference
 */
export const USER_PREFERENCE_VALUE = {
  ddmmmyyyy: 'DD MMM YYYY',
  mmmddyyyy: 'MMM DD YYYY',
  metric: 'Metric',
  imperial: 'Imperial',
  usStandard: 'US Standard',
  hours12: '12 hour',
  hours24: '24 hour',
  'en-US': 'English',
  'fr-FR': 'French',
  'de-DE': 'German',
  'it-IT': 'Italian',
  'pt-PT': 'Portuguese',
  'es-ES': 'Spanish'
};

/**
 * Helios preference and eRoutes preference value mapping
 * Key - eRoutes preference
 * Value - Helios preference
 */
export const USER_PREFERENCE_HELIOS_VALUE = {
  'DD MMM YYYY': 'ddmmmyyyy',
  'MMM DD YYYY': 'mmmddyyyy',
  Metric: 'metric',
  Imperial: 'imperial',
  'US Standard': 'usStandard',
  '12 hour': 'hours12',
  '24 hour': 'hours24',
  English: 'en-US',
  French: 'fr-FR',
  German: 'de-DE',
  Italian: 'it-IT',
  Portuguese: 'pt-PT',
  Spanish: 'es-ES',
  'HH:MM:SS 24hr': 'hours24',
  'HH:MM:SS AM/PM': 'hours12'
};

/**
 * Helios preference and CatProductivity preference value mapping
 * Key - Helios preference
 * Value - eRoutes preference
 */
export const CP_USER_PREFERENCE_VALUE = {
  ddmmmyyyy: 'DD MMM YYYY',
  mmmddyyyy: 'MMM DD, YYYY',
  mmddyyyy: 'MM/DD/YYYY',
  ddmmyyyy: 'DD/MM/YYYY',
  yyyymmdd: 'YYYY/MM/DD',
  metric: 'SI Units',
  usStandard: 'U.S. Customary',
  hours12: '12 hour',
  hours24: '24 hour',
  radixDot: 'Period',
  radixComa: 'Comma',
  'cs-CZ': 'cs-cz',
  'de-DE': 'de-de',
  'en-US': 'en-us',
  'es-419': 'es-xl', //Not present in Helios
  'es-ES': 'es-es',
  'fr-FR': 'fr-fr',
  'it-IT': 'it-it',
  'hu-HU': 'hu-hu',
  'nl-NL': 'nl-nl',
  'pl-PL': 'pl-pl',
  'pt-BR': 'pt-br',
  'pt-PT': 'pt-pt',
  'sv-SE': 'sv-se',
  'tr-TR': 'tr-tr',
  'ru-RU': 'ru-ru',
  'th-TH': 'th-th',
  'zh-CN': 'zh-ch',
  'ko-KR': 'ko-kr',
  'ja-JP': 'ja-jp',
  'bg-BG': 'bg-bg',
  'el-GR': 'el-gr',
  'da-DK': 'da-dk',
  'fi-FI': 'fi-fi',
  'id-ID': 'id-id',
  'no-NO': 'nb-no',
  'mn-MN': 'mn-mn',
  'ro-RO': 'ro-ro',
  'sl-SI': 'sl-si',
  'zh-TW': 'zh-tw',
  'ta-IN': 'ta-in'
};

/**
 * Helios preference and CatProductivity preference value mapping
 * Key - Helios preference
 * Value - eRoutes preference
 */
export const CP_DEFAULT_USER_GLOBAL_PREFERENCE_VALUE = {
  dateFormat: 'mmmddyyyy',
  language: 'en-US',
  numberFormat: 'radixDot',
  systemOfMeasurement: 'usStandard',
  timeFormat: 'hours24'
};

export const CP_DEFAULT_USER_CUSTOM_PREFERENCE_VALUE = {
  ucid: null
};
/**
 * Helios preference and eRoutes preference value mapping
 * Key - eRoutes preference
 * Value - Helios preference
 */
export const CP_USER_PREFERENCE_HELIOS_VALUE = {
  'DD MMM YYYY': 'ddmmmyyyy',
  'MMM DD, YYYY': 'mmmddyyyy',
  'MM/DD/YYYY': 'mmddyyyy',
  'DD/MM/YYYY': 'ddmmyyyy',
  'YYYY/MM/DD': 'yyyymmdd',
  'SI Units': 'metric',
  'U.S. Customary': 'usStandard',
  '12 hour': 'hours12',
  '24 hour': 'hours24',
  Period: 'radixDot',
  Comma: 'radixComa',
  'cs-cz': 'cs-CZ',
  'de-de': 'de-DE',
  'en-us': 'en-US',
  'es-xl': 'es-419', //Not present in Helios
  'es-es': 'es-ES',
  'fr-fr': 'fr-FR',
  'it-it': 'it-IT',
  'hu-hu': 'hu-HU',
  'nl-nl': 'nl-NL',
  'pl-pl': 'pl-PL',
  'pt-br': 'pt-BR',
  'pt-pt': 'pt-PT',
  'sv-se': 'sv-SE',
  'tr-tr': 'tr-TR',
  'ru-ru': 'ru-RU',
  'th-th': 'th-TH',
  'zh-ch': 'zh-CN',
  'ko-kr': 'ko-KR',
  'ja-jp': 'ja-JP',
  'bg-bg': 'bg-BG',
  'el-gr': 'el-GR',
  'da-dk': 'da-DK',
  'fi-fi': 'fi-FI',
  'id-id': 'id-ID',
  'nb-no': 'no-NO',
  'mn-mn': 'mn-MN',
  'ro-ro': 'ro-RO',
  'sl-si': 'sl-SI',
  'zh-tw': 'zh-TW',
  'ta-in': 'ta-IN'
};

export interface StepperStepConfig {
  steps: StepConfig[];
  btnConfig?: BtnConfig;
  title?: string;
  config?: AppConfig;
}

export interface StepConfig {
  component?: any;
  label: string;
  isOptional: boolean;
  isCompleted: boolean;
  visibilityCriteria?: Array<string>;
}

export interface CircularStepperConfig {
  color: string;
  background: string;
  radius: number;
  stroke: number;
  semicircle: boolean;
  rounded: boolean;
  clockwise: boolean;
  responsive: boolean;
  duration: number;
  animation: string;
  animationDelay: number;
}

interface BtnConfig {
  prevBtnLabel: string;
  nextBtnLabel: string;
  skipBtnLabel?: string;
}

interface AppConfig {
  addAppName: boolean;
  createGroupId: boolean;
}

export const APP_IDS = {
  PITMANAGEMENT: 3,
  EROUTES: 1
};

export const PREFERENCE_FIELD_ORDER = {
  TIME_FORMAT: ['12 hour', '24 hour'],
  UNITS: ['U.S. Customary', 'SI Units']
};
