import { DEMO_MODE_KEY, DEMO_MODE_RELOADED_KEY } from './utils.config';

let demoModeStatus = 'false';

export function setDemoModeStatus(status: string): void {
  demoModeStatus = status;
}

export function isDemoModeON(): boolean {
  return demoModeStatus === 'true';
}

export function removeDemoMode(): void {
  window.localStorage.removeItem(DEMO_MODE_KEY);
}

export function isReloadedForDemoMode(isChecked: string): boolean {
  return window.localStorage.getItem(DEMO_MODE_RELOADED_KEY) === isChecked;
}

export function isDemoModeONForInterceptor(): boolean {
  return window.localStorage.getItem(DEMO_MODE_KEY) === 'true';
}

export function setReloadStatusForDemoMode(status: string): void {
  window.localStorage.setItem(DEMO_MODE_RELOADED_KEY, status);
}

export function removeReloadForDemoMode(): void {
  window.localStorage.removeItem(DEMO_MODE_RELOADED_KEY);
}

export function setOrRemoveReloadStatus(): void {
  if (isReloadedForDemoMode('true')) {
    return setReloadStatusForDemoMode('false');
  }
  return removeReloadForDemoMode();
}
