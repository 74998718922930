import { utilsHelper } from '@Terra/shared/widgets/utils';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter } from 'rxjs/operators';

@Injectable()
export class SharedAccessGuard {
  private appMenu: any = {};
  private routePath: string = null;
  private isStateUpdated = false;
  private isRouteUpdated = false;
  redirectUrl = 'dashboard';

  constructor(private readonly store: Store<any>, private readonly router: Router) {
    this.stateSubscription();
    this.routeSubscription();
  }

  private stateSubscription() {
    this.store
      .select((state: any) => state.app.menuConfig)
      .subscribe((response: any) => {
        if (!response?.length) {
          return;
        }
        this.isStateUpdated = true;
        this.appMenu = response;
        this.canValidate();
      });
  }

  public routeSubscription(): void {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(({ urlAfterRedirects }: NavigationEnd) => {
      this.isRouteUpdated = true;
      this.routePath = utilsHelper.getRoutePathFromURL(urlAfterRedirects);
      this.canValidate();
    });
  }

  private canValidate() {
    if (this.isStateUpdated && this.isRouteUpdated) {
      const route = this.validateRoute(this.appMenu, this.routePath) || [];
      if (!route.length) {
        //when corresponding menu for the navigated route is not available, user redirected to dashboard
        this.router.navigateByUrl(this.redirectUrl);
      }
    }
  }

  private validateRoute(apiResponse, routeName) {
    if (!apiResponse || !routeName) {
      return false;
    }
    const isEqual = (apiName, rName) => apiName === rName;
    const validateFunc =
      (currentRouteMenuName = '') =>
      (menus = []) =>
        menus.filter(menu => {
          const findFunc = (items = [], callback) => items.find(item => callback(item));
          const check = currentMenu => {
            const cMenu = isEqual(currentMenu['pathName'], currentRouteMenuName);
            return cMenu || (currentMenu['children'] && findFunc(currentMenu['children'], check)) || false;
          };
          return check(menu);
        });
    const menuFilterFunc = validateFunc(routeName);
    return menuFilterFunc(apiResponse);
  }
}
