import { Action } from '@ngrx/store';
import { StepperStepConfig } from './models/shared-lib.model';

export interface AppData {
  selectedParty: any;
  x_application_id: string;
  thirdPartyIntegrationConfig: any;
  preferenceData: any;
  siteWizardStep: StepperStepConfig;
  Features: any;
  productFamilyConfig: any;
}

export interface AppState {
  readonly app: AppData;
}

export const appInitialState: AppData = {
  x_application_id: '2',
  selectedParty: null,
  Features: null,
  thirdPartyIntegrationConfig: {
    hasMiddleware: false
  },
  preferenceData: {
    dateFormat: 'MM/DD/YY',
    timeFormat: 'HH:MM:SS 24hr',
    unit: 'SI Units',
    autoRefresh: 'Enable',
    language: 'English',
    remember: false,
    timeZone: {
      id: 13,
      languageCode: null,
      languageName: null,
      name: '(UTC-07:00) Mountain Time (US & Canada)',
      standardName: 'Mountain Standard Time',
      timeZoneCode: '(UTC-06:00) MDT',
      timezone: 'America/Creston'
    },
    autoRefereshTimeInterval: 30000
  },
  siteWizardStep: {
    title: 'Stepper',
    btnConfig: {
      prevBtnLabel: 'Previous',
      nextBtnLabel: 'Next'
    },
    steps: [
      {
        component: null,
        label: 'Stepper 1',
        isOptional: false,
        isCompleted: false,
        visibilityCriteria: []
      },
      {
        component: null,
        label: 'Stepper 2',
        isOptional: false,
        isCompleted: false,
        visibilityCriteria: []
      }
    ]
  },
  productFamilyConfig: {}
};

export function appReducer(state = appInitialState, action: AppActions): AppData {
  if (action.type === AppActionTypes.ProductFamilyConfigLoadSuccess) {
    return { ...state, productFamilyConfig: action.payload };
  } else {
    return state;
  }
}

export enum AppActionTypes {
  DumpAction = '[App] DumpAction',
  ProductFamilyConfigLoadSuccess = '[App] ProductFamilyConfigLoadSuccess'
}

export class DumpAction implements Action {
  readonly type = AppActionTypes.DumpAction;
  // constructor(public payload: any) { }
}

export class ProductFamilyConfigLoadSuccess implements Action {
  readonly type = AppActionTypes.ProductFamilyConfigLoadSuccess;
  constructor(public payload: any) {}
}

export type AppActions = DumpAction | ProductFamilyConfigLoadSuccess;

/**
 * Interface for the 'Environment' data used in
 *  - EnvironmentState, and
 *  - environmentReducer
 */

/**
 * Interface to the part of the Store containing EnvironmentState
 * and other information related to EnvironmentData.
 */
export interface EnvironmentData {
  env: string;
  app_baseURL: string;
  woa_msBaseURL: string;
  appLoginURL?: string;
  auth_ms_baseURL: string;
  site_url?: SiteURL;
  assets_URL?: string;
  assigned_assets_URL: string;
  reports_Endpoints?: string;
  shift_url?: ShiftURL;
  application_ID: APPLICATIONID;
  template_ID: TEMPLATEID;
}

interface SiteURL {
  GET: string;
  POST: string;
  PUT: string;
  DELETE: string;
}

interface ShiftURL {
  GET: string;
  POST: string;
  PUT: string;
  DELETE: string;
}

interface APPLICATIONID {
  excel: FILETYPE;
}

interface TEMPLATEID {
  excel: FILETYPE;
}

interface FILETYPE {
  dev: number;
  local: number;
  int: number;
  qa: number;
  perf: number;
  stg: number;
  prod: number;
}

export interface EnvironmentState {
  readonly environment: EnvironmentData;
}

export const environmentInitialState: EnvironmentData = {
  env: '',
  app_baseURL: '',
  woa_msBaseURL: '',
  appLoginURL: '',
  auth_ms_baseURL: '',
  site_url: {
    GET: '',
    POST: '',
    PUT: '',
    DELETE: ''
  },
  assets_URL: '',
  assigned_assets_URL: '',
  reports_Endpoints: '',
  shift_url: {
    GET: '',
    POST: '',
    PUT: '',
    DELETE: ''
  },
  application_ID: {
    excel: {
      dev: null,
      int: null,
      qa: null,
      perf: null,
      stg: null,
      prod: null,
      local: null
    }
  },
  template_ID: {
    excel: {
      dev: null,
      local: null,
      int: null,
      qa: null,
      perf: null,
      stg: null,
      prod: null
    }
  }
};

export function environmentReducer(state = environmentInitialState, action: EnvironmentActions): EnvironmentData {
  switch (action.type) {
    case EnvironmentActionTypes.EnvironmentAction:
      return state;

    case EnvironmentActionTypes.EnvironmentLoaded: {
      return { ...state, ...action.payload };
    }

    case EnvironmentActionTypes.IdentifyEnvironment: {
      const envLocalIdentifier = action.payload;
      const modifiedenvState: EnvironmentData = {
        env: envLocalIdentifier,
        app_baseURL: '',
        woa_msBaseURL: '',
        appLoginURL: '',
        auth_ms_baseURL: '',
        site_url: environmentInitialState.site_url,
        assets_URL: '',
        assigned_assets_URL: '',
        application_ID: {
          excel: {
            dev: null,
            int: null,
            qa: null,
            perf: null,
            stg: null,
            prod: null,
            local: null
          }
        },
        template_ID: {
          excel: {
            dev: null,
            local: null,
            int: null,
            qa: null,
            perf: null,
            stg: null,
            prod: null
          }
        }
      };

      return {
        ...state,
        ...modifiedenvState
      };
    }

    default:
      return state;
  }
}

export enum EnvironmentActionTypes {
  EnvironmentAction = '[Environment] Action',
  LoadEnvironment = '[Environment] Load Data',
  EnvironmentLoaded = '[Environment] Data Loaded',
  IdentifyEnvironment = '[Environment] identifyEnv',
  DumpAction = '[Environment] DumpAction'
}

export class Environment implements Action {
  readonly type = EnvironmentActionTypes.EnvironmentAction;
}

export class EnvironmentLoaded implements Action {
  readonly type = EnvironmentActionTypes.EnvironmentLoaded;
  constructor(public payload: any) {}
}

export class IdentifyEnvironment implements Action {
  readonly type = EnvironmentActionTypes.IdentifyEnvironment;
  constructor(public payload: any) {}
}

export type EnvironmentActions = EnvironmentLoaded | IdentifyEnvironment | Environment;
