import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { CcButtonComponent } from '@cat-digital-workspace/shared-ui-core/button';

@Component({
  selector: 'app-stepper-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class StepperFooterComponent implements OnInit {
  @Input() stepperNextBtnConfig: BtnConfig;
  @Input() stepperPreviousBtnConfig: BtnConfig;
  @Input() stepperSaveExitBtnConfig: BtnConfig;
  @Input() btnNameConfig: any;
  @Input() workFlowTypeLable: string;

  @Output() next = new EventEmitter<any>();
  @Output() previous = new EventEmitter<any>();
  @Output() saveAndExit = new EventEmitter<any>();
  @Output() saveAndReview = new EventEmitter<any>();
  @ViewChild('nextBtn') nextBtnRef: ElementRef<CcButtonComponent> | any;
  @ViewChild('previousBtn') previousBtnRef: ElementRef<CcButtonComponent> | any;
  @ViewChild('saveAndExit') saveAndExitRef: ElementRef<CcButtonComponent> | any;
  @ViewChild('saveAndReview') saveAndReviewRef: ElementRef<CcButtonComponent> | any;
  jobsiteType = JobsiteTyre;

  constructor() {}

  ngOnInit() {}

  onNext() {
    this.next.emit(this.stepperNextBtnConfig.label);
    this.nextBtnRef._getHostElement().blur();
  }
  onPrevious() {
    this.previous.emit(this.stepperPreviousBtnConfig.label);
    this.previousBtnRef._getHostElement().blur();
  }

  onSaveAndExit(): void {
    this.saveAndExit.emit(this.btnNameConfig.UPDATE_AND_EXIT);

    this.saveAndExitRef ? this.saveAndExitRef._getHostElement().blur() : this.saveAndReviewRef._getHostElement().blur();
  }

  onSaveAndReview(): void {
    this.saveAndReview.emit(this.btnNameConfig.SAVE_AND_REVIEW);
    this.saveAndReviewRef._getHostElement().blur();
  }

  triggerBasedOnTheTypes(): void {
    this.workFlowTypeLable === this.jobsiteType.EDIT ? this.onSaveAndExit() : this.onSaveAndReview();
  }
}

export interface BtnConfig {
  label: string;
  disabled: boolean;
  showBtn?: boolean;
}

export const JobsiteTyre = {
  EDIT: 'edit'
};
