import { SpinnerModule } from '@Terra/shared/widgets/spinner';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CatButtonModule } from '@cat-digital-workspace/shared-ui-core/button';
import { CcListModule } from '@cat-digital-workspace/shared-ui-core/cc-list';
import { CcMenuModule } from '@cat-digital-workspace/shared-ui-core/contextual-menu';
import { CcMessageModule } from '@cat-digital-workspace/shared-ui-core/message';
import { CcTooltipModule } from '@cat-digital-workspace/shared-ui-core/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedLibEffects } from './+state/shared-lib.effects';
import { initialState as sharedLibInitialState, sharedLibReducer } from './+state/shared-lib.reducer';
import { FullScreenComponent } from './app-full-screen/app.fullscreen.component';
import { AppNoDataBannerComponent } from './app-no-data-banner/app-no-data-banner.component';
import { AppReportDownloadComponent } from './app-report-download/app.report.download';
import { AppSpinnerComponent } from './app-spinner/app-spinner.component';
import { StepperFooterComponent } from './footer/footer.component';
import { PreventStartsWithSpaceDirective } from './prevent-starts-with-space.directive';
import { ProgressBarAlertComponent } from './progress-bar-alert/progress-bar-alert.component';
import { SharedLibService } from './shared-lib.service';
import { SharedAccessGuard } from './sharedAccess.guard';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('sharedLib', sharedLibReducer, {
      initialState: sharedLibInitialState
    }),
    EffectsModule.forFeature([SharedLibEffects]),
    SpinnerModule,
    CcMenuModule,
    CcListModule,
    CcTooltipModule,
    CatButtonModule,
    TranslocoModule,
    CcMessageModule
  ],
  providers: [SharedAccessGuard, SharedLibService],
  declarations: [
    AppSpinnerComponent,
    FullScreenComponent,
    ProgressBarAlertComponent,
    AppReportDownloadComponent,
    PreventStartsWithSpaceDirective,
    StepperFooterComponent,
    AppNoDataBannerComponent
  ],
  exports: [
    AppSpinnerComponent,
    FullScreenComponent,
    ProgressBarAlertComponent,
    AppReportDownloadComponent,
    PreventStartsWithSpaceDirective,
    StepperFooterComponent,
    AppNoDataBannerComponent
  ]
})
export class SharedLibModule {}
