import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule, TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { scopeLoader } from '@Terra/shared/shared-translate-loader';
import { SiteMsUtilService } from './siteMsUtils/siteMsUtil.service';
import { CPUtilService } from './util.service';
@NgModule({
  imports: [CommonModule, TranslocoModule],
  providers: [
    CPUtilService,
    SiteMsUtilService,
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'sharedUtil',
        loader: scopeLoader((lang, root) => import(`./${root}/${lang}.json`))
      }
    }
  ]
})
export class CPUtilsModule {}
