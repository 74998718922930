<div class="stepper-footer">
  <div class="action action-button">
    @if (stepperPreviousBtnConfig && stepperPreviousBtnConfig.showBtn !== false) {
      <button
        [setTheme]="true"
        cc-btn-secondary
        cc-btn-large
        class="prev-btn"
        [disabled]="stepperPreviousBtnConfig.disabled"
        (click)="onPrevious()"
        #previousBtn
        >
        <span class="btn-content">
          {{ stepperPreviousBtnConfig.label | transloco }}
        </span>
      </button>
    }
    @if (stepperSaveExitBtnConfig && stepperSaveExitBtnConfig.showBtn) {
      <button
        [setTheme]="true"
        cc-btn-secondary
        cc-btn-large
        class="prev-btn"
        [disabled]="stepperSaveExitBtnConfig.disabled"
        (click)="onSaveAndExit()"
        #saveAndExit
        >
        <span class="btn-content">
          {{ stepperSaveExitBtnConfig.label | transloco }}
        </span>
      </button>
    }

    @if (workFlowTypeLable && !(stepperSaveExitBtnConfig && stepperSaveExitBtnConfig.showBtn)) {
      <button
        [setTheme]="true"
        cc-btn-secondary
        cc-btn-large
        class="prev-btn"
        (click)="triggerBasedOnTheTypes()"
        #saveAndReview
        >
        <span class="btn-content">
          {{ (workFlowTypeLable === jobsiteType.EDIT ? btnNameConfig.UPDATE_AND_EXIT : btnNameConfig.SAVE_AND_REVIEW) | transloco }}
        </span>
      </button>
    }

    @if (stepperNextBtnConfig && stepperNextBtnConfig.showBtn !== false) {
      <button
        [setTheme]="true"
        cc-btn-primary
        cc-btn-large
        class="next-btn"
        [disabled]="stepperNextBtnConfig.disabled"
        (click)="onNext()"
        #nextBtn
        >
        <span class="btn-content">
          {{ stepperNextBtnConfig.label | transloco }}
        </span>
      </button>
    }
  </div>
</div>
