import { OperatorList, OperatorListResponse, OperatorMapping } from "./oms.model";

/**
 * Create dictionary with mapping operatorId(keyId/oprId) as key and operatorName(xtrnlId) as value for the entire JSON
 */
export const prepareOperatorList = (operatorList: OperatorListResponse): Record<string, OperatorMapping> => {
    const operatorIdMapping: OperatorMapping = {};
    const operatorKeyMapping: OperatorMapping = {};
    operatorList?.operators?.map((operator: OperatorList) => {
        Object.assign(operatorIdMapping, { [operator.oprId]: operator.xtrnlId !== '' ? operator.xtrnlId : null });
        Object.assign(operatorKeyMapping, { [operator.keyId]: operator.xtrnlId !== '' ? operator.xtrnlId : null });
    });
    return { oprIdMap: operatorIdMapping, keyIdMap: operatorKeyMapping };
};