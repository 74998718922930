export const MOCK_FUEL_BURN = 'shared-utils/images/fuel_burn_rate.svg';
export const MOCK_IMAGE_SRC = 'shared-utils/images/total_volume_perDay.svg';
export const MOCK_IMAGE_REMAINING = 'shared-utils/images/volume_remaining.svg';
export const MOCK_IMAGE_GRADE = 'shared-utils/images/on_grade.svg';
export const dashboardUtilInfo = {
  imageSrc_cycleTime: 'shared-utils/images/cycle_time.svg',
  imageSrc_hourlyProd: 'shared-utils/images/hourly_production.svg',
  imageSrc_loadsPerDay: 'shared-utils/images/loads_per_day.svg',
  imageSrc_loadsPerHour: 'shared-utils/images/loads_per_hour.svg',
  imageSrc_loadsPerCycle: 'shared-utils/images/payload_per_cycle.svg',
  imageSrc_totalCycleCount: 'shared-utils/images/total_cycle_count.svg',
  imageSrc_totalPayload: 'shared-utils/images/total_payload.svg',
  imageSrc_totalRunTime: 'shared-utils/images/total_runtime.svg',
  imageSrc_fuelBurnRate: MOCK_FUEL_BURN,
  imageSrc_idleFuelBurn: 'shared-utils/images/idle_fuel_burn.svg',
  imageSrc_idleFuelBurnPer: 'shared-utils/images/idle_fuel_burn_percentage.svg',
  imageSrc_longIdle: 'shared-utils/images/long_idle.svg',
  imageSrc_mediumIdle: 'shared-utils/images/medium_idle.svg',
  imageSrc_totalFuelBurnRate: 'shared-utils/images/total_fuel_burn_rate.svg',
  imageSrc_working: 'shared-utils/images/working.svg',
  imageSrc_idle: 'shared-utils/images/idle.svg',
  imageSrc_costPerHour: 'shared-utils/images/cost_per_hour.svg',
  imageSrc_costPerPayload: 'shared-utils/images/cost_per_payload.svg',
  imageSrc_costPerVolume: 'shared-utils/images/cost_per_volume.svg',
  imageSrc_idleCost: 'shared-utils/images/idle_cost.svg',
  imageSrc_workingCost: 'shared-utils/images/working_cost.svg',
  imageSrc_cutVolume: 'shared-utils/images/cut_volume.svg',
  imageSrc_fillVolume: 'shared-utils/images/fill_volume.svg'
};

export const PREFRENCE_UNIT = {
  US_CUSTOMARY: 'U.S. Customary',
  SI_UNITS: 'SI Units'
};

export const UOM_CONSTANT = {
  hr: 'hr',
  sec: 'sec',
  min: 'min',
  tonnes: 'tonnes',
  tons: 'tons',
  litreperhour: 'L/hr',
  litre: 'L',
  gallon: 'gal',
  gallonperhour: 'gal/hr',
  m: 'm<sup>3</sup>',
  yd: 'yd<sup>3</sup>',
  percentage: '%',
  cubicMeter: 'cubicMeters',
  currency: '$',
  costperpayload: 'CostPerPayload',
  costpervolume: 'CostPerVolume'
};

export const KPI_CONFIG = {
  CycleTime_1: {
    uom: 'sec',
    imageSrc: dashboardUtilInfo.imageSrc_cycleTime
  },
  EmptyStoppedTime_2: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/empty_stopped_time.svg'
  },
  HourlyProduction_3: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_hourlyProd
  },
  LoadedStoppedTime_4: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/loaded_stopped_time.svg'
  },
  LoadsPerDay_5: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerDay
  },
  LoadsPerHour_6: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerHour
  },
  PayloadPerCycle_7: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerCycle
  },
  TotalCycleCount_8: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_totalCycleCount
  },
  TotalPayload_9: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_totalPayload
  },
  TotalRuntime_10: {
    uom: 'sec',
    imageSrc: dashboardUtilInfo.imageSrc_totalRunTime
  },
  TotalVolume_11: {
    uom: 'cubicMeters',
    imageSrc: 'shared-utils/images/total_volume.svg'
  },
  VolumePerDay_12: {
    uom: 'cubicMeters',
    imageSrc: 'shared-utils/images/volume_per_day.svg'
  },
  HourlyVolumeProduction_113: {
    uom: 'cubicMeters',
    imageSrc: 'shared-utils/images/volume_hourly_production.svg'
  },
  HourlyVolumeProduction_114: {
    uom: 'cubicMeters',
    imageSrc: 'shared-utils/images/volume_hourly_production.svg'
  },
  LoadTime_13: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/load_time.svg'
  },
  PassCountPerCycle_14: {
    uom: '',
    imageSrc: 'shared-utils/images/pass_count_per_cycle.svg'
  },
  CycleTime_15: {
    uom: 'sec',
    imageSrc: dashboardUtilInfo.imageSrc_cycleTime
  },
  HourlyProduction_16: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_hourlyProd
  },
  LoadsPerDay_17: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerDay
  },
  LoadsPerHour_18: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerHour
  },
  PayloadPerCycle_19: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerCycle
  },
  PileCleanupTime_20: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/pile_cleanup_time.svg'
  },
  RoadingTime_21: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/roading_time.svg'
  },
  StoppedTime_22: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/stopped_time.svg'
  },
  TotalPayload_23: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_totalPayload
  },
  TotalRuntime_24: {
    uom: 'sec',
    imageSrc: dashboardUtilInfo.imageSrc_totalRunTime
  },
  CycleTime_25: {
    uom: 'sec',
    imageSrc: dashboardUtilInfo.imageSrc_cycleTime
  },
  EmptyStoppedTime_26: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/empty_stopped_time.svg'
  },
  HourlyProduction_27: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_hourlyProd
  },
  LoadsPerDay_28: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerDay
  },
  LoadsPerHour_29: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerHour
  },
  LoadedStoppedTime_30: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/loaded_stopped_time.svg'
  },
  LoadTime_31: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/load_time.svg'
  },
  PayloadPerCycle_32: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerCycle
  },
  TotalCycleCount_33: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_totalCycleCount
  },
  TotalPayload_34: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_totalPayload
  },
  TotalRuntime_35: {
    uom: 'sec',
    imageSrc: dashboardUtilInfo.imageSrc_totalRunTime
  },
  TotalVolume_36: {
    uom: 'cubicMeters',
    imageSrc: 'shared-utils/images/total_volume.svg'
  },
  VolumePerDay_37: {
    uom: 'cubicMeters',
    imageSrc: 'shared-utils/images/volume_per_day.svg'
  },
  CyclesPerDay_38: {
    uom: '',
    imageSrc: 'shared-utils/images/cycles_per_day.svg'
  },
  CyclesPerHour_39: {
    uom: '',
    imageSrc: 'shared-utils/images/cycles_per_hour.svg'
  },
  CycleTime_40: {
    uom: 'sec',
    imageSrc: dashboardUtilInfo.imageSrc_cycleTime
  },
  HourlyProduction_41: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_hourlyProd
  },
  PassCount_42: {
    uom: '',
    imageSrc: 'shared-utils/images/pass_count_per_cycle.svg'
  },
  PayloadPerCycle_43: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerCycle
  },
  PileCleanupTime_44: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/pile_cleanup_time.svg'
  },
  RoadingTime_45: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/roading_time.svg'
  },
  StoppedTime_46: {
    uom: 'sec',
    imageSrc: 'shared-utils/images/stopped_time.svg'
  },
  TotalCycleCount_47: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_totalCycleCount
  },
  TotalPayload_48: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_totalPayload
  },
  TotalRuntime_49: {
    uom: 'sec',
    imageSrc: dashboardUtilInfo.imageSrc_totalRunTime
  },
  FuelBurnRate_50: {
    uom: 'L/hr',
    imageSrc: dashboardUtilInfo.imageSrc_fuelBurnRate
  },
  IdleFuelBurn_51: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurn
  },
  IdleFuelPercentage_52: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurnPer
  },
  LongIdle_53: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_longIdle
  },
  MediumIdle_54: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_mediumIdle
  },
  ShortIdle_55: {
    uom: '%',
    imageSrc: 'shared-utils/images/short_idle.svg'
  },
  TotalFuelBurn_56: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_totalFuelBurnRate
  },
  Working_57: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_working
  },
  FuelBurnRate_58: {
    uom: 'L/hr',
    imageSrc: MOCK_FUEL_BURN
  },
  Idle_59: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idle
  },
  IdleFuelBurn_60: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurn
  },
  IdleFuelPercentage_61: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurnPer
  },
  LongIdle_62: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_longIdle
  },
  MediumIdle_63: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_mediumIdle
  },
  TotalFuelBurn_64: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_totalFuelBurnRate
  },
  Working_65: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_working
  },
  FuelBurnRate_66: {
    uom: 'L/hr',
    imageSrc: MOCK_FUEL_BURN
  },
  Idle_67: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idle
  },
  Seatbelt_Compliance: {
    uom: '%',
    imageSrc: null
  },
  Total_Runtime: {
    uom: 'sec',
    imageSrc: null
  },
  IdleFuelBurn_68: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurn
  },
  IdleFuelPercentage_69: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurnPer
  },
  LongIdle_70: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_longIdle
  },
  MediumIdle_71: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_mediumIdle
  },
  TotalFuelBurn_72: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_totalFuelBurnRate
  },
  Working_73: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_working
  },
  FuelBurnRate_74: {
    uom: 'L/hr',
    imageSrc: MOCK_FUEL_BURN
  },
  IdleFuelBurn_75: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurn
  },
  IdleFuelPercentage_76: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurnPer
  },
  LongIdle_77: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_longIdle
  },
  MediumIdle_78: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_mediumIdle
  },
  ShortIdle_79: {
    uom: '%',
    imageSrc: 'shared-utils/images/short_idle.svg'
  },
  TotalFuelBurn_80: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_totalFuelBurnRate
  },
  Working_81: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_working
  },
  FuelBurnRate_82: {
    uom: 'L/hr',
    imageSrc: MOCK_FUEL_BURN
  },
  Idle_83: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idle
  },
  IdleFuelBurn_84: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurn
  },
  IdleFuelPercentage_85: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurnPer
  },
  LongIdle_86: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_longIdle
  },
  MediumIdle_87: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_mediumIdle
  },
  TotalFuelBurn_88: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_totalFuelBurnRate
  },
  Working_89: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_working
  },
  CostPerHour_90: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_costPerHour
  },
  CostPerPayload_91: {
    uom: 'CostPerPayload',
    imageSrc: dashboardUtilInfo.imageSrc_costPerPayload
  },
  CostPerVolume_92: {
    uom: 'CostPerVolume',
    imageSrc: dashboardUtilInfo.imageSrc_costPerVolume
  },
  IdleCost_93: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_idleCost
  },
  WorkingCost_94: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_workingCost
  },
  CostPerHour_95: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_costPerHour
  },
  CostPerPayload_96: {
    uom: 'CostPerPayload',
    imageSrc: dashboardUtilInfo.imageSrc_costPerPayload
  },
  CostPerVolume_97: {
    uom: 'CostPerVolume',
    imageSrc: dashboardUtilInfo.imageSrc_costPerVolume
  },
  IdleCost_98: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_idleCost
  },
  WorkingCost_99: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_workingCost
  },
  CostPerHour_100: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_costPerHour
  },
  CostPerPayload_101: {
    uom: 'CostPerPayload',
    imageSrc: dashboardUtilInfo.imageSrc_costPerPayload
  },
  CostPerVolume_102: {
    uom: 'CostPerVolume',
    imageSrc: dashboardUtilInfo.imageSrc_costPerVolume
  },
  IdleCost_103: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_idleCost
  },
  WorkingCost_104: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_workingCost
  },
  CostPerHour_105: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_costPerHour
  },
  CostPerPayload_106: {
    uom: 'CostPerPayload',
    imageSrc: dashboardUtilInfo.imageSrc_costPerPayload
  },
  CostPerVolume_107: {
    uom: 'CostPerVolume',
    imageSrc: dashboardUtilInfo.imageSrc_costPerVolume
  },
  IdleCost_108: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_idleCost
  },
  WorkingCost_109: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_workingCost
  },
  CostPerHour_110: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_costPerHour
  },
  IdleCost_111: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_idleCost
  },
  WorkingCost_112: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_workingCost
  },
  FuelBurnRate_156: {
    uom: 'L/hr',
    imageSrc: MOCK_FUEL_BURN
  },
  Idle_157: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idle
  },
  IdleFuelBurn_158: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurn
  },
  IdleFuelPercentage_159: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurnPer
  },
  LongIdle_160: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_longIdle
  },
  MediumIdle_161: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_mediumIdle
  },
  TotalFuelBurn_162: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_totalFuelBurnRate
  },
  Working_163: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_working
  },
  PayloadPerHour: {
    uom: 'tonnes',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerHour
  },
  VolumePerHour: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_loadsPerHour
  },
  Utilization: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_working
  },
  TotalCycleCount: {
    uom: '',
    imageSrc: dashboardUtilInfo.imageSrc_totalCycleCount
  },
  CyclesPerHour: {
    uom: '',
    imageSrc: 'shared-utils/images/cycles_per_hour.svg'
  },
  'GradeVolumeCompleted/Day_172': {
    uom: 'cubicMeters',
    imageSrc: MOCK_IMAGE_SRC
  },
  GradeCutVolumeCompleted_173: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_cutVolume
  },
  GradeFillVolumeCompleted_174: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_fillVolume
  },
  GradeVolumeRemaining_176: {
    uom: 'cubicMeters',
    imageSrc: MOCK_IMAGE_REMAINING
  },
  GradeCutVolumeRemaining_177: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_cutVolume
  },
  GradeFillVolumeRemaining_178: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_fillVolume
  },
  GradePercent_175: {
    uom: '%',
    imageSrc: MOCK_IMAGE_GRADE
  },
  CostPerHour_164: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_costPerHour
  },
  IdleCost_165: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_idleCost
  },
  WorkingCost_166: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_workingCost
  },
  GradeCostPerVolume_179: {
    uom: '$',
    imageSrc: 'shared-utils/images/grade_cost_per_volume.svg'
  },
  FuelBurnRate_140: {
    uom: 'L/hr',
    imageSrc: MOCK_FUEL_BURN
  },
  Idle_141: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idle
  },
  IdleFuelBurn_142: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurn
  },
  IdleFuelPercentage_143: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_idleFuelBurnPer
  },
  LongIdle_144: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_longIdle
  },
  MediumIdle_145: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_mediumIdle
  },
  TotalFuelBurn_146: {
    uom: 'L',
    imageSrc: dashboardUtilInfo.imageSrc_totalFuelBurnRate
  },
  Working_147: {
    uom: '%',
    imageSrc: dashboardUtilInfo.imageSrc_working
  },
  CostPerHour_148: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_costPerHour
  },
  IdleCost_149: {
    uom: '$',
    imageSrc: 'shared-utils/images/idle_cost.svg'
  },
  WorkingCost_150: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_workingCost
  },
  CompactCostPerVolume_180: {
    uom: '$',
    imageSrc: dashboardUtilInfo.imageSrc_costPerVolume
  },
  CompactVolumeRemaining_185: {
    uom: 'cubicMeters',
    imageSrc: MOCK_IMAGE_REMAINING
  },
  CompactCutVolumeRemaining_186: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_cutVolume
  },
  CompactFillVolumeRemaining_187: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_fillVolume
  },
  CompactOnGradePercent_184: {
    uom: '%',
    imageSrc: MOCK_IMAGE_GRADE
  },
  CompactFillVolumeCompleted_182: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_fillVolume
  },
  CompactCoverage_183: {
    uom: 'cubicMeters',
    imageSrc: 'shared-utils/images/coverage.svg'
  },
  'CompactVolumeCompleted/Day_181': {
    uom: 'cubicMeters',
    imageSrc: MOCK_IMAGE_SRC
  },
  LoadoutFillVolumeCompleted_1073: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_fillVolume
  },
  LoadoutFillVolumeRemaining_1077: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_fillVolume
  },
  LoadoutCutVolumeCompleted_1072: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_cutVolume
  },
  LoadoutCutVolumeRemaining_1076: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_cutVolume
  },
  LoadoutGradePercent_1074: {
    uom: '%',
    imageSrc: MOCK_IMAGE_GRADE
  },
  'LoadoutVolumeCompleted/Day_1071': {
    uom: 'cubicMeters',
    imageSrc: MOCK_IMAGE_SRC
  },
  LoadoutVolumeRemaining_1075: {
    uom: 'cubicMeters',
    imageSrc: MOCK_IMAGE_REMAINING
  },
  LoaderFillVolumeCompleted_1066: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_fillVolume
  },
  LoaderFillVolumeRemaining_1070: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_fillVolume
  },
  LoaderCutVolumeCompleted_1065: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_cutVolume
  },
  LoaderCutVolumeRemaining_1069: {
    uom: 'cubicMeters',
    imageSrc: dashboardUtilInfo.imageSrc_cutVolume
  },
  LoaderGradePercent_1067: {
    uom: '%',
    imageSrc: MOCK_IMAGE_GRADE
  },
  'LoaderVolumeCompleted/Day_1064': {
    uom: 'cubicMeters',
    imageSrc: MOCK_IMAGE_SRC
  },
  LoaderVolumeRemaining_1068: {
    uom: 'cubicMeters',
    imageSrc: MOCK_IMAGE_REMAINING
  }
};
export const COMPACT_KPI_ID = [
  'CompactCostPerVolume_180',
  'CompactVolumeRemaining_185',
  'CompactCutVolumeRemaining_186',
  'CompactFillVolumeRemaining_187',
  'CompactOnGradePercent_184',
  'CompactFillVolumeCompleted_182',
  'CompactCoverage_183',
  'CompactVolumeCompleted/Day_181'
];

export const GRADE_KPI_ID = [
  'GradeVolumeCompleted/Day_172',
  'GradeCutVolumeCompleted_173',
  'GradeFillVolumeCompleted_174',
  'GradeVolumeRemaining_176',
  'GradeCutVolumeRemaining_177',
  'GradeFillVolumeRemaining_178',
  'GradePercent_175'
];

export const LOADER_KPI_ID = [
  'LoaderFillVolumeCompleted_1066',
  'LoaderFillVolumeRemaining_1070',
  'LoaderCutVolumeCompleted_1065',
  'LoaderCutVolumeRemaining_1069',
  'LoaderGradePercent_1067',
  'LoaderVolumeCompleted/Day_1064',
  'LoaderVolumeRemaining_1068'
];
export const LOADOUT_KPI_ID = [
  'LoadoutFillVolumeCompleted_1073',
  'LoadoutFillVolumeRemaining_1077',
  'LoadoutCutVolumeCompleted_1072',
  'LoadoutCutVolumeRemaining_1076',
  'LoadoutGradePercent_1074',
  'LoadoutVolumeCompleted/Day_1071',
  'LoadoutVolumeRemaining_1075'
];

export const COST_CURRENCY_CONFIG = [
  { name: '$ (US Dollar)', key: 'US_DOLLAR', symbol: '$' },
  { name: 'C$ (Canadian Dollar)', key: 'CANADIAN_DOLLAR', symbol: 'C$' },
  { name: 'Mex$ (Mexican Peso)', key: 'MEXICAN_PESO', symbol: 'Mex$' },
  { name: 'A$ (Australian Dollar)', key: 'AUSTRALIAN_DOLLAR', symbol: 'A$' },
  { name: 'NZ$ (New Zealand Dollar)', key: 'NEW_ZEALAND_DOLLAR', symbol: 'NZ$' },
  { name: '€ (Euro)', key: 'EURO', symbol: '€' },
  { name: '£ (Pound Sterling)', key: 'POUND_STERLING', symbol: '£' },
  { name: 'Kč (Czech Koruna)', key: 'CZECH_KORUNA', symbol: 'Kč' },
  { name: '₱ (Peso)', key: 'PESO', symbol: '₱' },
  { name: 'Ft (Hungarian Forint)', key: 'HUNGARIAN_FORINT', symbol: 'Ft' },
  { name: 'zł (Polish złoty)', key: 'POLISH_ZLOTY', symbol: 'zł' },
  { name: 'R$ (Brazilian real)', key: 'BRAZILIAN_REAL', symbol: 'R$' },
  { name: 'kr (Swedish Krona)', key: 'SWEDISH_KRONA', symbol: 'kr' },
  { name: '₺ (Turkish lira)', key: 'TURKISH_LIRA', symbol: '₺' },
  { name: 'RUB (Russian ruble)', key: 'RUSSIAN_RUBLE', symbol: 'RUB' },
  { name: '฿ (Thai Baht)', key: 'THAI_BAHT', symbol: '฿' },
  { name: '¥ (Renminbi)', key: 'RENMINBI', symbol: '¥' },
  { name: 'JP¥ (Japanese Yen)', key: 'JAPANESE_YEN', symbol: 'JP¥' },
  { name: '₩ (South Korean won)', key: 'SOUTH_KOREAN_WON', symbol: '₩' },
  { name: '₩ (North Korean won)', key: 'NORTH_KOREAN_WON', symbol: '₩' },
  { name: 'kr (Norwegian krone)', key: 'NORWEGIAN_KRONE', symbol: 'kr' },
  { name: 'CHF (Swiss Franc)', key: 'SWISS_FRANC', symbol: 'CHF' },
  { name: 'R (South African rand)', key: 'SOUTH_AFRICAN_RAND', symbol: 'R' }
];
