import { Action } from '@ngrx/store';

export enum ActionTypes {
  ShowAppSpinner = '[SharedLib] Show App Spinner',
  HideAppSpinner = '[SharedLib] Hide App Spinner',
  HookCrds = '[SharedLib] hook crds',
  HookCrdsSuccess = '[SharedLib] hook crds success',
  DownloadExcel = '[SharedLib] download excel',
  DownloadExcelSuccess = '[SharedLib] download excel success',
  Error = '[SharedLib] Error',
  ProgressBarAlertProgress = '[SharedLib] Progress Bar Alert Is InProgress',
  ProgressBarAlertSuccess = '[SharedLib] Progress Bar Alert Success',
  ProgressBarAlertFailure = '[SharedLib] Progress Bar Alert Is Failure',
  resetData = '[SharedLib] Reset data',
  ToggleFullScreen = '[SharedLib] Fullscreen Mode',
  LogOut = '[SharedLib] App Logout'
}

export class ShowAppSpinner implements Action {
  readonly type = ActionTypes.ShowAppSpinner;
}
export class HideAppSpinner implements Action {
  readonly type = ActionTypes.HideAppSpinner;
}

type SpinnerActions = ShowAppSpinner | HideAppSpinner;

export class ProgressBarAlertProgressState implements Action {
  readonly type = ActionTypes.ProgressBarAlertProgress;
  constructor(public progressType: any) {}
}
export class ProgressBarAlertSuccessState implements Action {
  readonly type = ActionTypes.ProgressBarAlertSuccess;
}

export class ProgressBarAlertFailureState implements Action {
  readonly type = ActionTypes.ProgressBarAlertFailure;
}

type ProgressBarAlertActions = ProgressBarAlertProgressState | ProgressBarAlertSuccessState | ProgressBarAlertFailureState;

/**
 * post call to crds api
 */
export class HookCrds implements Action {
  readonly type = ActionTypes.HookCrds;
  constructor(public payload: any) {}
}

/**
 * get response from crds api and store that in store
 */
export class HookCrdsSuccess implements Action {
  readonly type = ActionTypes.HookCrdsSuccess;
  constructor(public payload: any) {}
}

/**
 * to handle api calls errors
 */
export class Error implements Action {
  readonly type = ActionTypes.Error;
  constructor(public payload: any) {}
}

export class DownloadExcel implements Action {
  readonly type = ActionTypes.DownloadExcel;
  constructor(public payload: any) {}
}

export class DownloadExcelSuccess implements Action {
  readonly type = ActionTypes.DownloadExcelSuccess;
  constructor(public payload: any) {}
}

export class ToggleFullScreen implements Action {
  readonly type = ActionTypes.ToggleFullScreen;
  constructor(public payload: boolean) {}
}
export class ResetData implements Action {
  readonly type = ActionTypes.resetData;
}

export class LogOut implements Action {
  readonly type = ActionTypes.LogOut;
  constructor(public payload: boolean) {}
}

export type SharedActions =
  | SpinnerActions
  | HookCrds
  | HookCrdsSuccess
  | DownloadExcel
  | DownloadExcelSuccess
  | Error
  | ProgressBarAlertActions
  | ResetData
  | ToggleFullScreen
  | LogOut;
