export const NODE_SERVER = {
  TERRASITE_ROUTE: '/terraSite'
};

export const API_ENDPOINTS = {
  siteBoundaryStatus: '/api/v4/sites/{site_guid}/siteboundaryintersects'
};

export const configStrings = {
  contentType: 'Content-Type',
  applicationJson: 'application/json'
};
