import { ActionTypes, SharedActions } from './shared-lib.actions';

export const APP_FEATURE_KEY = 'app';

export interface SharedLibData {
  spinner: boolean;
  downloadMessage: string;
  blob: any;
  progressBarAlert: any;
  fullScreenMode: boolean;
  error: any;
  logout: boolean;
}

export interface SharedLibState {
  readonly sharedLib: SharedLibData;
}

export const initialState: SharedLibData = {
  spinner: false,
  progressBarAlert: null,
  downloadMessage: '',
  blob: null,
  fullScreenMode: false,
  error: null,
  logout: null
};

export function sharedLibReducer(state = initialState, action: SharedActions): SharedLibData {
  switch (action.type) {
    case ActionTypes.ShowAppSpinner:
      return { ...state, spinner: true };

    case ActionTypes.HideAppSpinner:
      return { ...state, spinner: false };

    case ActionTypes.ProgressBarAlertProgress: {
      const progressPayload = { loaderActive: true, template: 'progressAlertProgress', type: action.progressType };
      return { ...state, progressBarAlert: progressPayload };
    }

    case ActionTypes.ProgressBarAlertSuccess: {
      const progressPayload = { loaderActive: false, template: '' };
      return { ...state, progressBarAlert: progressPayload };
    }

    case ActionTypes.ProgressBarAlertFailure: {
      const progressPayload = { loaderActive: true, template: 'progressAlertFailure' };
      return { ...state, progressBarAlert: progressPayload };
    }

    case ActionTypes.HookCrdsSuccess: {
      return {
        ...state,
        downloadMessage: action.payload
      };
    }

    case ActionTypes.DownloadExcelSuccess: {
      return {
        ...state,
        blob: action.payload
      };
    }

    case ActionTypes.ToggleFullScreen: {
      return {
        ...state,
        fullScreenMode: action.payload
      };
    }

    case ActionTypes.resetData: {
      return {
        ...state,
        downloadMessage: '',
        blob: null
      };
    }

    case ActionTypes.Error: {
      return {
        ...state,
        error: action.payload
      };
    }

    case ActionTypes.LogOut: {
      return {
        ...state,
        logout: action.payload
      };
    }

    default:
      return state;
  }
}
