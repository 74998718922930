import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_ENDPOINTS, NODE_SERVER } from './cpmListUtil.config';
import { CPMResponse } from './cpmListUtil.model';

@Injectable()
export class CpmListUtilService {
  constructor(private readonly http: HttpClient) {}

  getListType(queryParams: string): Observable<CPMResponse> {
    const params = new HttpParams().set(NODE_SERVER.HANDLER_URL, `${API_ENDPOINTS.remoteList}${queryParams}`);
    return this.http.get<CPMResponse>(NODE_SERVER.GET_LIST_ROUTE, { params });
  }
}
