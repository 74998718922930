import { Injectable } from '@angular/core';
import { MessageBar } from '@cat-digital-workspace/shared-ui-core/message';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { fetch } from '@ngrx/router-store/data-persistence';
import { map } from 'rxjs/operators';
import { SharedLibService } from '../shared-lib.service';
import * as sharedLibAction from './shared-lib.actions';
import { SharedLibState } from './shared-lib.reducer';
@Injectable()
export class SharedLibEffects {
  crds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedLibAction.ActionTypes.HookCrds),
      fetch({
        run: (action: sharedLibAction.HookCrds, state: SharedLibState) =>
          this.sharedLibService.postDataToCrdsService(action.payload).pipe(
            map(response => {
              if (response) {
                return new sharedLibAction.HookCrdsSuccess(response);
              }
              return new sharedLibAction.HookCrdsSuccess(null);
            })
          ),
        onError: (action: sharedLibAction.HookCrds, error) => {
          const statusCodes = [0, 500, 502, 408];
          if (!error.status || statusCodes.indexOf(error.status) > -1) {
            const msg = this.translocoService.translate('WIDGETS_SHARED_LIB_REPORT_DOWNLOAD_EXCEL_FAILED');
            this.messageBar.open(msg, 'error', [], {
              hostType: 'overlay',
              duration: 5000,
              horizontalPosition: 'center',
              verticalPosition: 'top'
            });
          } else {
            new sharedLibAction.Error(error.error.message);
          }
        }
      })
    )
  );

  download$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sharedLibAction.ActionTypes.DownloadExcel),
      fetch({
        run: (action: sharedLibAction.DownloadExcel, state: SharedLibState) =>
          this.sharedLibService.downloadExcelService(action.payload).pipe(
            map(response => {
              if (response) {
                const msg = this.translocoService.translate('WIDGETS_SHARED_LIB_REPORT_DOWNLOAD_EXCEL_SUCCESS');
                this.messageBar.open(msg, 'success', [], {
                  hostType: 'overlay',
                  duration: 5000,
                  horizontalPosition: 'center',
                  verticalPosition: 'top'
                });
              }
              return new sharedLibAction.DownloadExcelSuccess(response);
            })
          ),
        onError: (action: sharedLibAction.DownloadExcel, error) => new sharedLibAction.Error(error)
      })
    )
  );

  constructor(
    private readonly sharedLibService: SharedLibService,
    private readonly actions$: Actions,
    private readonly translocoService: TranslocoService,
    private readonly messageBar: MessageBar
  ) {}
}
