const CRDS_LANGUAGE_CODES = {
  'bg-bg': 'bg-BG',
  'cs-cz': 'cs-CZ',
  'da-dk': 'da-DK',
  'de-de': 'de-DE',
  'el-gr': 'el-GR',
  'en-us': 'en-US',
  'es-es': 'es-ES',
  'es-xl': 'es-XL',
  'fr-fr': 'fr-FR',
  'hu-hu': 'hu-HU',
  'it-it': 'it-IT',
  'ja-jp': 'jp-JN',
  'jp-jn': 'jp-JN',
  'ko-kr': 'ko-KR',
  'nb-no': 'nb-NO',
  'nl-nl': 'nl-NL',
  'pl-pl': 'pl-PL',
  'pt-br': 'pt-BR',
  'pt-pt': 'pt-PT',
  'ro-ro': 'ro-RO',
  'ru-ru': 'ru-RU',
  'sl-si': 'sl-SI',
  'sv-se': 'sv-SE',
  'th-th': 'th-TH',
  'tr-tr': 'tr-TR',
  'zh-Hans': 'zh-HANS',
  'zh-ch': 'zh-HANS',
  'fi-fi': 'sv-FI',
  'id-id': 'id-ID',
  'mn-mn': 'mn-MN',
  'ta-in': 'ta-IN',
  'zh-cn': 'zh-HANS',
  'zh-tw': 'zh-TW'
};

// strictly don't change the order of language codes
const VOC_LANUAGE_CODES = {
  'en-us': 'en',
  'es-xl': 'es_LA',
  'es-es': 'es_LA',
  'pt-br': 'pt_BR',
  'pt-pt': 'pt_BR',
  'fr-fr': 'fr_FR',
  'de-de': 'de',
  'ru-ru': 'ru_RU',
  'ja-jp': 'ja',
  'it-it': 'it',
  'nl-nl': 'nl_NL',
  'cs-cz': 'cs',
  'tr-tr': 'tr',
  'pl-pl': 'pl',
  'sv-se': 'sv',
  'zh-ch': 'zh_CN',
  'th-th': 'th',
  'ko-kr': 'ko_KR',
  'hu-hu': 'hu',
  'bg-bg': 'bg',
  'el-gr': 'el',
  'da-dk': 'da',
  'fi-fi': 'fi',
  'id-id': 'id_ID',
  'nb-no': 'nb_NO',
  'mn-mn': 'mn',
  'ro-ro': 'ro',
  'sl-si': 'sl_SI',
  'zh-tw': 'zh_CN',
  'ta-in': 'ta'
};

export const ISO_LANUAGE_CODES = {
  'en-US': 'en-us',
  'es-ES': 'es-es',
  es: 'es-xl',
  fr: 'fr-fr',
  de: 'de-de',
  'pt-BR': 'pt-br',
  pt: 'pt-pt',
  ru: 'ru-ru',
  ja: 'jp-jn',
  it: 'it-it',
  nl: 'nl-nl',
  cs: 'cs-cz',
  tr: 'tr-tr',
  pl: 'pl-pl',
  sv: 'sv-se',
  'zh-CN': 'zh-Hans',
  th: 'th-th',
  ko: 'ko-kr',
  hu: 'hu-hu',
  bg: 'bg-bg',
  da: 'da-dk',
  fi: 'fi-fi',
  el: 'el-gr',
  id: 'id-id',
  mn: 'mn-mn',
  nb: 'nb-no',
  ro: 'ro-ro',
  sl_SI: 'sl-si',
  'zh-TW': 'zh-tw',
  ta: 'ta-in'
};

export const BCP_LANGUAGE_CODES = {
  'bg-bg': 'bg-BG',
  'cs-cz': 'cs-CZ',
  'da-dk': 'da-DK',
  'de-de': 'de-DE',
  'el-gr': 'el-GR',
  'en-us': 'en-US',
  'es-es': 'es-ES',
  'es-xl': 'es-419',
  'fr-fr': 'fr-FR',
  'hu-hu': 'hu-HU',
  'it-it': 'it-IT',
  'jp-jn': 'ja-JP',
  'ja-jp': 'ja-JP',
  'ko-kr': 'ko-KR',
  'nb-no': 'nb-NO',
  'nl-nl': 'nl-NL',
  'pl-pl': 'pl-PL',
  'pt-br': 'pt-BR',
  'pt-pt': 'pt-PT',
  'ro-ro': 'ro-RO',
  'ru-ru': 'ru-RU',
  'sl-si': 'sl-SL',
  'sv-se': 'sv-SE',
  'th-th': 'th-TH',
  'tr-tr': 'tr-TR',
  'zh-Hans': 'zh-Hans',
  'zh-ch': 'zh-Hans',
  'fi-fi': 'fi-FI',
  'id-id': 'id-ID',
  'mn-mn': 'mn-MN',
  'zh-tw': 'zh-Hant',
  'ta-in': 'ta-IN',
  en: 'en-US',
  da: 'da-DK',
  de: 'de-DE',
  fr: 'fr-FR',
  is: 'is-IS',
  it: 'it-IT',
  no: 'nb-NO',
  pt: 'pt-PT',
  rw: 'es-ES',
  'sv-FI': 'fi-FI',
  'sv-SE': 'sv-SE'
};

export const BROWSER_LANGUAGE_CODES = {
  bg: 'bg-BG',
  cs: 'cs-CZ',
  da: 'da-DK',
  de: 'de-DE',
  el: 'el-GR',
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  hu: 'hu-HU',
  it: 'it-IT',
  ja: 'ja-JP',
  ko: 'ko-KR',
  no: 'nb-NO',
  nb: 'nb-NO',
  nl: 'nl-NL',
  pl: 'pl-PL',
  'pt-br': 'pt-BR',
  pt: 'pt-PT',
  ro: 'ro-RO',
  ru: 'ru-RU',
  sl: 'sl-SL',
  sv: 'sv-SE',
  th: 'th-TH',
  tr: 'tr-TR',
  'zh-TW': 'zh-Hant',
  zh: 'zh-Hans',
  fi: 'fi-FI',
  id: 'id-ID',
  mn: 'mn-MN',
  ta: 'ta-IN'
};

export const LANG_CODES = {
  crds: CRDS_LANGUAGE_CODES,
  voc: VOC_LANUAGE_CODES,
  iso: ISO_LANUAGE_CODES,
  bcp: BCP_LANGUAGE_CODES
};

export const LANG_SYSTEMS = {
  CRDS: 'crds',
  VOC: 'voc',
  ISO: 'iso',
  BCP: 'bcp'
};
